<template>
  <b-card title="Dealer">
    <b-col cols="12">
      <b-col cols="12" class="mb-2" v-if="dealerSelectAvailable">
        <div>
          <span>Dealer <span style="color:red">(*)</span></span>
          <v-select
            v-model="dealerChoice"
            @input="getDashboardDealer"
            placeholder="---Select Dealer---"
            :options="dealer"
            label="dealerName"
            :clearable="false"
          />
        </div>
      </b-col>
      <b-col cols="12" class="mb-2 hide-on-mobile">
        <div class="filter-responsive">
          <div class="input-group">
            <div class="input-group-prepend">
              <b-dropdown
                style="margin: 0 !important;"
                id="dropdown-1"
                :text="selectedFilter"
                class="m-md-2"
              >
                <b-dropdown-item @click="dropDownMenuClick" field="model"
                  >Model</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <b-form-input
              v-model="toFilter"
              placeholder="Filter.."
            />
            <b-button @click="doFilter" variant="primary">Filter</b-button>
            <b-button @click="clearFilter" variant="primary">Clear</b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="hide-on-desktop">
        <div class="filter-responsive">
          <div class="input-group">
            <div class="input-group-prepend">
              <b-dropdown
                style="margin: 0 !important;"
                id="dropdown-1"
                :text="selectedFilter"
                class="m-md-2"
              >
                <b-dropdown-item @click="dropDownMenuClick" field="model"
                  >Model</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <b-form-input
              v-model="toFilter"
              placeholder="Filter.."
            />
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="mt-2 mb-2 hide-on-desktop">
        <b-button @click="doFilter" variant="primary">Filter</b-button>
        <b-button @click="clearFilter" variant="primary">Clear</b-button>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-input-group>
          <b-form-input
            v-model="searchByDo"
            type="search"
            placeholder="Search by DO"
          />
          <b-input-group-append>
            <b-button @click="doSearch" variant="primary">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :per-page="perPage"
            :current-page="currentPage"
            :items="dealers"
            :fields="fields"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(doCreated)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.doCreated"
                  @click.prevent="detailCreated(row)"
                >
                  {{ row.item.doCreated }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(doProcessing)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.doProcessing"
                  @click.prevent="detailProcessing(row)"
                >
                  {{ row.item.doProcessing }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(delivered)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.delivered"
                  @click.prevent="detailDelivered(row)"
                >
                  {{ row.item.delivered }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(warranty)="row">
              <div class="text-center">
                <a
                  style="text-decoration:underline rgb(37, 150, 190)"
                  href="#"
                  v-if="row.item.warranty"
                  @click.prevent="detailWarranty(row)"
                >
                  {{ row.item.warranty }}
                </a>
                <p v-else>
                  0
                </p>
              </div>
            </template>
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    @click="doEditDealer(row)"
                    variant="info"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {userAccess} from '@/utils/utils'
import lodash from "lodash";
import vSelect from "vue-select";

export default {
  data() {
    return {
      dealerChoice: "",
      dealers: [],
      form: {
        data: ["Dealer 1", "Dealer 2"],
      },
      fields: [
        {
          key: "itemModel",
          label: "Model",
          filterable: true,
          sortable: true,
          class: "text-center",
        },
        {
          key: "doCreated",
          label: "new do",
          filterable: true,
          sortable: true,
          class: "text-center",
        },
        { key: "doProcessing", label: "do processing", class: "text-center" },
        { key: "delivered", label: "delivered", class: "text-center" },
        { key: "warranty", label: "warranty registered", class: "text-center" },
      ],
      search: "",
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      searchByDo : "",
      tableLoading: true,
      toFilter: "",
      selectedFilter: "Model",
      selectedField: "model",
      type: "Set Up",
      typeOptions: ["Set Up", "Not Set Up", "Skip Scan"],
      typeDo: "Set Up",
      typeDoOptions: ["Set Up", "Not Set Up"],
      dealerCodeExist:''
    };
  },
  mounted() {
    // console.log('query',this.$route.query)
    this.$store.dispatch("dealer/getAllDealers");

    this.$store
      .dispatch('auth/fetchUser');

    this.$store.dispatch("dealer/getCurrentDealer").then((x) => {
      var payload = {};
      //console.info('x ',x);
      //console.info('dealer ',this.getDealer)
      if (this.getDealer) {
        //if user is dealer
        this.dealerChoice=this.getDealer;
        this.getDashboardDealer()
      }
      
    });
    this.checkParamDealerCode()
    
  },
  methods: {
    detailWarranty(row) {
      let payload = {
        itemModel: row.item.itemModel,
        dealerCode: this.dealerChoice.dealerCode,
        sapDo: this.searchByDo
      };
      this.$store.dispatch("dashboard/getWarranty", payload);
      this.$router.push({
        name: "dashboard-warranty",
        params: payload,
      });
    },
    detailDelivered(row) {
      let payload = {
        itemModel: row.item.itemModel,
        dealerCode: this.dealerChoice.dealerCode,
        sapDo: this.searchByDo
      };
      this.$router.push({
        name: "dashboard-delivered",
        params: payload,
      });
    },
    detailProcessing(row) {
      let payload = {
        itemModel: row.item.itemModel,
        dealerCode: this.dealerChoice.dealerCode,
        sapDo: this.searchByDo
      };
      this.getDoProcessing(payload);
      this.$router.push({
        name: "dashboard-processing",
        params: payload,
      });
    },
    detailCreated(row) {
      let payload = {
        itemModel: row.item.itemModel,
        dealerCode: this.dealerChoice.dealerCode,
        sapDo: this.searchByDo
      };
      this.getDoCreated(payload);
      this.$router.push({
        name: "dashboard-created",
        params: payload,
      });
    },
    getDashboardDealer(Dcode) {
      let findDealer = this.dealer.find(({ dealerCode }) => dealerCode === Dcode);
      if (findDealer !== undefined) {
        this.dealerChoice = findDealer;
      }
      this.currentPage = 1;
      let payload = {
        dealerCode: this.dealerChoice.dealerCode,
        itemModel: this.toFilter,
        sapDo: this.searchByDo, // Tambahkan filter sapDo
      };
      this.getDashboard(payload)
        .then((el) => {
          this.dealers = el;
          this.processMetadata(el);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    checkParamDealerCode () {
      let existingDealerCode = this.$route.query.dealerCode
      if(existingDealerCode) {
        this.getDashboardDealer(existingDealerCode)
      }else{
        return
      }
    },
    ...mapActions({
      getDealers: "dealer/getDealerWithQuery",
      getDashboard: "dashboard/getDealer",
      getDoCreated: "dashboard/getDoCreated",
      getDoProcessing: "dashboard/getDoProcessing",
      getDelivered: "dashboard/getDelivered",
      getWarranty: "dashboard/getWarranty",
    }),
    getAllInventories() {
      return this.dealers;
    },
    
    dropDownMenuClick(event) {
      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute("field");

      //every change selected filter, will reset type to :
      this.typeDo = "Set Up";
      this.type = "Set Up";
    },
    clearFilter() {
      this.selectedField = "model";
      this.selectedFilter = "Model";
      this.toFilter = "";
      //

      this.getDashboardDealer()
    },
    getFilter() {
      if (this.selectedFilter === null) {
        return {};
      }
      let filter = {};

      let tempValue = this.toFilter;
      let tempFilter = this.selectedField;

      filter[tempFilter] = tempValue;

      return filter;
    },
    doFilter() {
      let payload = {
        dealerCode: this.dealerChoice.dealerCode,
        itemModel: this.toFilter,
        sapDo: this.searchByDo,
      };
      this.getDashboard(payload)
        .then((el) => {
          this.dealers = el;
          this.processMetadata(el);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    doSearch() {
      // Lakukan pencarian berdasarkan nilai searchByDo di sini
      let payload = {
        dealerCode: this.dealerChoice.dealerCode,
        itemModel: this.toFilter,
        sapDo: this.searchByDo, // Tambahkan filter sapDo
      };
      this.getDashboard(payload)
        .then((el) => {
          this.dealers = el;
          this.processMetadata(el);
        })
        .catch((err) => {
          console.log({ err });
        });

      // Anda dapat menyesuaikan logika pencarian sesuai kebutuhan Anda
    },
    processMetadata(list) {
      this.totalRows = list.length
      this.tableLoading = false;
    },
  },
  computed: {
    dealerSelectAvailable() {
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"];
      if (tempDealer!=null){
        return false
      }
      let result = userAccess("Dashboard Dealer", "dashboard_menu");
      console.log(result);
      // console.log("Delivery Orders Sumitomo",result)
      console.info('result permission ',result )
      if(!result){
        return false;
      }
      return result.view;
    },
    getDealer() {
      //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
      //kalau user bukan dealer, return undefined
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"];
      //console.log('tempdealer ',tempDealer)
      if (tempDealer === null) {
        tempDealer = this.dealerChoice;
      }
      return tempDealer;
    },
    user() {
      //buat cek informasi akun yang sedang login
      var userData = ( this.$store.getters["auth/getActiveUser"].name)?
        this.$store.getters["auth/getActiveUser"]:
        {name:'nouser',roleRef:{name:'non'}}
      //console.info('userData ',userData)
      
      return userData
    },
    dealer() {
      //console.log('dealer ',this.$store.getters["dealer/getDealers"])
      return this.$store.getters["dealer/getDealers"].filter(
        (x) => x.status === ""
      );
    },
  },
  created() {
    document.title = "Dashboard| RSP";
    this.$store.dispatch("dealer/getAllDealers");
    
    // this.getDealers({
    //   page: this.currentPage,
    //   entry: this.perPage,
    // }).then((data) => this.processMetadata(data.metadata));
  },
};
</script>
<style scoped>
@media (min-width: 872px) {
  .hide-on-desktop { display: none !important; }
}

@media (max-width: 871px) {
  .hide-on-mobile { display: none !important; }
}

.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
  margin: 0 0 0 5px;
}

.filter-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-columns: 20%; */
  column-gap: 10px;
}


@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 0 0 0 0;
    width: 100px;
  }
}
</style>
